/**
 * 參考文件: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html
 * AWS JavaScript SDK Documentation
 */

// @ts-ignore
import s3SdkKernel, {S3SdkKernelInterface} from 'kernel/libs/multipartUpload/s3SdkKernel'

// @ts-ignore
import {
  InitProviderConfigInterface,
  ListUploadedPartsResultInterface,
  S3CredentialsType,
  UnfinishedUploadFiles,
  UploadFilePartRequestParams,
  UploadFilePartResponse,
  ListPartsRequestParams,
  MultipartUploadPart,
  CompleteMultipartUploadResult,
  CompleteMultipartUploadRequestParams, createMultipartUploadRequestParams,
// @ts-ignore
} from 'kernel/libs/multipartUpload/multipartUploadType'

class MultipartUploadProviderS3 {
  s3SdkKernel: S3SdkKernelInterface
  constructor() {
    this.s3SdkKernel = s3SdkKernel
  }

  /**
   * 初始化provider
   * @param {S3CredentialsType} credentials provider憑證
   * @param {InitProviderConfigInterface} config provider init config
   */
  init(credentials: S3CredentialsType, config: InitProviderConfigInterface) {
    this.s3SdkKernel.init(credentials, config)
  }

  /**
   * 列出所有未上傳完成的檔案資訊
   * 參考: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#listMultipartUploads-property
   * @returns Promise<UnfinishedUploadFiles[]>
   */
  async listMultipartUploads(bucket: string) : Promise<UnfinishedUploadFiles> {
    const result = await this.s3SdkKernel.getS3Method('listMultipartUploads', {
      Bucket: bucket
    })
    return {
      bucket: result.Bucket,
      nextMarker: result.NextKeyMarker,
      nextUploadIdMarker: result.NextUploadIdMarker,
      isTruncated: result.IsTruncated,
      maxUploads: result.MaxUploads,
      uploads: result.Uploads.map((item: any) => ({
        key: item.Key,
        uploadId: item.UploadId,
      }))
    }
  }

  /**
   * 上傳檔案切片
   * @param {UploadFilePartRequestParams} params
   * @returns {Promise<UploadFilePartResponse>}
   */
  async uploadPart(params: UploadFilePartRequestParams) : Promise<UploadFilePartResponse> {
    const result = await this.s3SdkKernel.getS3Method('uploadPart', {
      Body: params.fileChunk,
      Bucket: params.bucket,
      Key: params.path,
      PartNumber: params.partNumber,
      UploadId: params.uploadId,
    })
    return { etag: result.ETag }
  }

  /**
   * 發起multipart upload
   * 參考: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#createMultipartUpload-property
   * @param params
   * @returns Promise<{uploadId: string}>
   *   example
   *   const result = {
   *      Bucket: "examplebucket",
   *      Key: "largeobject",
   *      UploadId: "ibZBv_75gd9r8lH_gqXatLdxMVpAlj6ZQjEs.OwyF3953YdwbcQnMA2BLGn8Lx12fQNICtMw5KyteFeHw.Sjng--"
   *   }
   */
  async createMultipartUpload(params: createMultipartUploadRequestParams ) : Promise<{uploadId: string}> {
    const result = await this.s3SdkKernel.getS3Method('createMultipartUpload', {
      Key: params.objectKey,
      ContentType: params.fileType,
      Bucket: params.bucket,
      ACL: params.acl,
    })

    return {
      uploadId: result.UploadId
    }
  }

  /**
   * 取消上傳
   * @returns {Promise<void>}
   */
  async abortMultipart(params: { objectKey: string, uploadId: string, bucket: string }) : Promise<any>{
    return await this.s3SdkKernel.getS3Method('abortMultipartUpload', {
      Bucket: params.bucket,
      Key: params.objectKey,
      UploadId: params.uploadId,
    })
  }

  /**
   * 取得物件數性
   * @returns {Promise<void>}
   */
  async getObjectAttributes(params: { objectKey: string, bucket: string }) : Promise<any>{
    return await this.s3SdkKernel.getS3Method('getObjectAttributes', {
      Bucket: params.bucket,
      Key: params.objectKey,
      ObjectAttributes: ['StorageClass', 'ObjectSize'],
    })
  }

  /**
   * 列出已上傳的片段
   * 參考文件: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#listParts-property
   * @param params
   * @returns Promise<ListUploadedPartsResultInterface>
   */
  async listParts(params: ListPartsRequestParams) : Promise<ListUploadedPartsResultInterface> {
    const requestParams = <{
      Key: string,
      UploadId: string,
      NextPartNumberMarker: number|undefined
      Bucket: string,
    }>{
      Key: params.objectKey,
      UploadId: params.uploadId,
      Bucket: params.bucket,
    }
    if(params.nextPartNumberMarker) {
      requestParams.NextPartNumberMarker = params.nextPartNumberMarker
    }
    const result = await this.s3SdkKernel.getS3Method('listParts', requestParams)
    // @ts-ignore
    return {
      isTruncated: result.IsTruncated, // 代表還有其他片段
      parts: result.Parts.map((part: any) => ({
        partNumber: part.PartNumber,
        eTag: part.ETag,
      }))
    }
  }

  /**
   * 合併所有檔案片段
   * 參考文件: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#completeMultipartUpload-property
   * @param params
   */
  async completeMultipartUpload(params: CompleteMultipartUploadRequestParams) : Promise<CompleteMultipartUploadResult> {
    const requestParams = <{
      Key: string,
      MultipartUpload: {
        Parts: { PartNumber: number, ETag: string }[]
      },
      Bucket: string,
      UploadId: string
    }>{
      Key: params.objectKey,
      MultipartUpload: {
        Parts: params.parts.map(part => ({ PartNumber: part.partNumber, ETag: part.eTag }) ),
      },
      Bucket: params.bucket,
      UploadId: params.uploadId,
    }
    const result = await this.s3SdkKernel.getS3Method('completeMultipartUpload', requestParams)
    return {
      key: result.Key,
      location: result.Location,
    }
  }
}

export default new MultipartUploadProviderS3()

