const CHUNK_SIZE = 1024*1024*5 // 檔案切片大小, 5MB

declare type scanChunkCallback = (chunkNo: number, fileChunk: Blob | void) => void

/**
 * 此Class為處理檔案切片相關功能
 * 包含取得切片、迭代切片、取得切片數量
 */
class fileHelper {
  file: File
  chunkSize: number
  chunkQuantity: number

  constructor(file: File) {
    this.file = file
    this.chunkSize = CHUNK_SIZE
    this.chunkQuantity = 0
  }

  /**
   * 取得檔案切片數量
   * @return {number}
   */
  getChunkQuantity() : number {
    return Math.ceil(this._getFileSize() / this.chunkSize)
  }

  /**
   * 取得檔案大小
   * @return {number}
   */
  _getFileSize() : number {
    if(this.file instanceof File === false) return 0
    return this.file.size
  }

  async scanChunks(callback: scanChunkCallback) : Promise<void> {
    this.chunkQuantity = this.getChunkQuantity()
    for(let chunkNo=1; chunkNo <= this.chunkQuantity; chunkNo++) {
      const fileChunk = this._getFileChunk(chunkNo)
      await callback(chunkNo, fileChunk)
    }
  }

  _getFileChunk(chunkNo: number) : Blob|void{
    const start = (chunkNo-1) * this.chunkSize
    const end = Math.min(start + this.chunkSize, this._getFileSize())
    let fileChunk
    try {
      fileChunk = this.file.slice(start, end)
    } catch (error) {
      console.warn(`getFileChunk failure, chunkNo: ${chunkNo}`)
      console.error(error)
      throw error
      return
    }
    return fileChunk
  }
}

export default fileHelper

export declare interface FileHelperInterface {
  scanChunks(callback: scanChunkCallback) : Promise<void>
  getChunkQuantity() : number
}
