/**
 * 比較不常用的功能放這邊
 */
export default {
  methods: {
    /**
     * 列出全部未上傳檔案(debug用)
     */
    async listMultipartUploads() {
      await this.initStsToken()
      this.initProvider()
      let result = []
      try {
        result = await this.multiPartUploadLibrary.listMultipartUploads(this.bucket)
        console.warn(result)
      } catch (error) {
        console.warn(error)
      }
      return result
    },
    async getObjectAttributes(path) {
      await this.initStsToken()
      this.initProvider()
      let result = null
      try {
        result = await this.multiPartUploadLibrary.getObjectAttributes(path)
        console.warn(result)
      } catch (error) {
        console.warn(error)
      }
      return result
    },
    async abortAllUnfinishedUploads() {
      const result = await this.listMultipartUploads()
      for(const file of result.uploads) {
        await this.multiPartUploadLibrary.abortMultipart({
          uploadId: file.uploadId,
          key: file.key,
        })
        this.$snotify.info(
          this.$t(`multipart_upload.abort_successfully`, { uploadId: file.uploadId}),
          null
        )
      }
    },
    /**
     * 是否跳出捨棄未完成上傳的提醒
     * 選擇完檔案後如果是的跟未完成上傳不同的檔案
     * 跳出提醒詢問是否捨棄未完成的上傳
     * 確定就砍掉storage存的未完成進度
     */
    detectAbortUnfinishedUpload() {
      if(!this.file) return false // 無檔案
      if(!this.unfinishedUploadData) return false // 無未完成檔案
      if(this.file.name != this.unfinishedUploadData.filename) return true
      if(this.file.type != this.unfinishedUploadData.type) return true
      if(this.file.size != this.unfinishedUploadData.size) return true
      return false
    },
    abortUnfinishedUploadWarning() {
      this.$apopup.base({
        title: this.$t(`multipart_upload.abort_unfinished_upload`),
        content: `
<p>${this.$t('multipart_upload.data.filename')}: ${this.unfinishedUploadData.filename}</p>
<p>${this.$t('multipart_upload.data.progress')}: ${this.unfinishedUploadData.progress}%</p>
<p>${this.$t('multipart_upload.apply_confirm')}</p>
`,
        isHtml: true,
        applyCallback: async () => {
          await this.multiPartUploadLibrary.abortMultipart({
            uploadId: this.unfinishedUploadData.uploadId,
            key: this.unfinishedUploadData.objectKey,
          })
          const uploadId = this.unfinishedUploadData.uploadId
          this.multiPartUploadLibrary.storage.deleteUploadId(uploadId)
          this.unfinishedUploadData = null
          if(typeof this.onFileChangeCallback === 'function') this.onFileChangeCallback(this.file)
        },
        closeCallback: async (isCancel) => {
          if(isCancel) {
            await this.$nextTick()
            this.file = null
          }
        },
      })
    },
    // 參考: backend/Modules/Base/Constants/FileConstants.php
    getFileType() {
      const type = this.unfinishedUploadData.type
      if(new RegExp('pdf').test(type)) return 'pdf'
      if(new RegExp('image').test(type)) return 'image'
      if(new RegExp('video').test(type)) return 'video'
      return 'default'
    },
    /**
     * 在資料庫建立檔案資訊(檔案管理)
     */
    async createFileData(fileObjectKey) {
      if(this.disabledCreateFileData === true) return null
      const params = {
        size: this.unfinishedUploadData.size,
        filename: this.unfinishedUploadData.filename,
        path: fileObjectKey,
        type: this.getFileType(),
        file_type: this.systemFileType,
        extra_file_base_url: this.systemFileExtraFileBaseUrl,
      }
      let result = null
      try {
         result = await this.$api.collection.fileApi.createViaData(params)
      } catch (error) {
        console.error(error)
      }
      return result
    },
  },
}
