/**
 * 此mixin專責處理STS token
 * 外部請直接使用`initStsToken`做初始化
 * 並可於data內的`stsToken`取得sts token
 * 或`stsTokenLoading`取得sts token請求loading狀態(boolean)
 */
export default {
  data: () => ({
    multipartUploadStorageKey: 'multipart-upload-token',
    stsToken: null,
    stsTokenLoading: false,
  }),
  methods: {
    cleanUnusedStsToken() {
      const storageStsToken = this.$storage.get(this.multipartUploadStorageKey)
      if(!this.$eagleLodash.get(storageStsToken, 'Credentials.Expiration')) return null
      const expiredAt = this.$dayjs(storageStsToken.Credentials.Expiration).subtract(1, 'hour')
      // 剩下不到1hr就不用了
      const now = this.$dayjs().format('YYYY-MM-DD HH:mm:ss')
      const isExpired = expiredAt.isBefore(now)
      if(isExpired === true) {
        this.$storage.delete(this.multipartUploadStorageKey)
      }
    },
    async initStsToken() {
      this.stsTokenLoading = true
      this.stsToken = this.getStorageStsToken()
      if(this.stsToken) {
        this.stsTokenLoading = false
        return
      }
      await this.requestStsToken()
      this.stsTokenLoading = false
    },
    // 取得storage內的sts token
    getStorageStsToken() {
      const storageStsToken = this.$storage.get(this.multipartUploadStorageKey)
      if(!this.$eagleLodash.get(storageStsToken, 'Credentials.Expiration')) return null
      const expiredAt = this.$dayjs(storageStsToken.Credentials.Expiration)
      // 剩下不到1hr就不用了
      const oneHourAgo = this.$dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss')
      const isExpired = expiredAt.isBefore(oneHourAgo)
      if(isExpired === true) return null
      return storageStsToken
    },
    // 請求新的sts token
    async requestStsToken() {
      try {
        this.stsToken = await this.$api.collection.multipartUpload.requestCredential()
        this.$storage.set(this.multipartUploadStorageKey, this.stsToken)
      } catch (error) {
        console.error(error)
        let message = null
        if(error.status == 406) message = this.$t(`error.storage.out_of_limit`)
        this.$snotify.error(
          message,
          this.$t('file.upload.failure')
        )
      } finally {

      }
    },
  },
}
