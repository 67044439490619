// @ts-ignore
import * as AWS from 'aws-sdk'

import {
  S3CredentialsType,
  InitProviderConfigInterface
// @ts-ignore
} from 'kernel/libs/multipartUpload/multipartUploadType'

class s3SdkKernel {
  public s3?: AWS.S3

  init(s3Credentials: S3CredentialsType, config: InitProviderConfigInterface) : void {
    const {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    } = s3Credentials

    AWS.config.update({
      accessKeyId,
      secretAccessKey,
      sessionToken,
    })
    this.s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      region: config.region,
    })
  }

  getS3Method(methodName: string, params?: any) : Promise<any>|(() => void) {
    const self = this
    // @ts-ignore
    if(typeof this.s3[methodName] != 'function') {
      console.warn(`s3 ${methodName} function not found`)
      return async () => {}
    }

    return new Promise((resolve, reject) => {
      // @ts-ignore
      self.s3[methodName](params, (error, data) =>{
        if (error) {
          console.warn(`${methodName} failure`)
          console.warn(error)
          reject(error)
          return
        }

        resolve(data)
      })
    })
  }
}

export default new s3SdkKernel()

export declare interface S3SdkKernelInterface {
  init: (credentials: S3CredentialsType, config: InitProviderConfigInterface) => void
  getS3Method(methodName: string, params?: any) : Promise<any>|(() => void)
}
