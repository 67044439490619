// @ts-ignore
import localStorage from 'kernel/libs/storage/localStorage'
const STORAGE_DATA_KEY = 'multipart-upload'

// @ts-ignore
import {
  StorageFileItemInterface,
  UpdateProgressStatusInterface,
  UploadStatusInterface
} from 'kernel/libs/multipartUpload/multipartUploadType'

/**
 * 此class主要處理暫存的未上傳檔案完成檔案資訊(StorageFileItemInterface)
 */
class storage {
  constructor() {
    // @ts-ignore
    this.localStorage = localStorage()
    this.removeExpiredFiles()
  }

  _now() : string {
    // @ts-ignore
    return window.rootVm.$dayjs().format('YYYY-MM-DD HH:mm:ss')
  }

  /**
   * 刪除過期的未上傳檔案
   */
  removeExpiredFiles() : void {
    const storageData = this.getStorageData()
    if(!storageData) return
    storageData.files = storageData.files.filter(fileItem => {
      // @ts-ignore
      const sixHoursAgs = window.rootVm.$dayjs().subtract(6, 'hours') // 超過六小時砍掉
      const isExpired = sixHoursAgs.isAfter(fileItem.createdAt)
      return isExpired === false
    })
    // @ts-ignore
    this.localStorage.set(STORAGE_DATA_KEY, storageData)
  }

  /**
   * 取得storage存的檔案處理進度
   * @returns {
   *  files: []
   * }
   */
  getStorageData() : {files: StorageFileItemInterface[]} {
    // @ts-ignore
    let storageData = this.localStorage.get(STORAGE_DATA_KEY)
    if(!storageData) {
      storageData = {
        files: []
      }
      // @ts-ignore
      this.localStorage.set(STORAGE_DATA_KEY, storageData)
    }
    if(!Array.isArray(storageData.files)) {
      storageData.files = []
    }
    return storageData
  }

  /**
   * 比對"storage未上傳完成檔案"與"當下檔案"是否為相同
   * @param fileItem
   * @param file
   * @param targetUid
   */
  _checkIsSameFileRule(fileItem: StorageFileItemInterface, file: File, targetUid: string|null) : boolean {
    if(fileItem.filename != file.name) return false // 不同檔名
    if(fileItem.size != file.size) return false // 不同大小

    // 有ID就比對ID
    if(fileItem.targetUid) {
      return fileItem.targetUid === targetUid
    }
    return true
  }

  /**
   * 寫入uploadId+檔案資料到localStorage中
   * @param uploadId
   * @param file
   */
  saveUploadIdToStorage(objectKey: string, uploadId: string, file: File, targetUid: string|null) : void {
    const storageData = this.getStorageData()
    const index = storageData.files.findIndex(fileItem => {
      return this._checkIsSameFileRule(fileItem, file, targetUid)
    })
    const fileItem = <StorageFileItemInterface>{
      uploadId,
      objectKey,
      targetUid,
      filename: file.name,
      type: file.type,
      size: file.size,
      progress: 0,
      uploaded: 0,
      total: 0,
      createdAt: this._now(),
    }

    // 不存在就新增到陣列
    if(index === -1) {
      storageData.files.push(fileItem)
    }

    // 已存在直接覆寫
    else {
      storageData.files[index] = fileItem
    }

    // @ts-ignore
    this.localStorage.set(STORAGE_DATA_KEY, storageData)
  }

  getUploadIdByFile(file: File, targetUid: string|null = null) : string|null {
    const storageData = this.getStorageData()
    const fileItem = storageData.files.find(fileItem => {
      return this._checkIsSameFileRule(fileItem, file, targetUid)
    })
    if(!fileItem) return null
    return fileItem.uploadId
  }

  getUploadDataByFile(file: File, targetUid: string|null = null) : StorageFileItemInterface|null {
    const storageData = this.getStorageData()
    const fileItem = storageData.files.find(fileItem => {
      return this._checkIsSameFileRule(fileItem, file, targetUid)
    })
    return fileItem || null
  }

  /**
   * 透過uploadId刪除storage暫存檔案
   * @param uploadId
   */
  deleteUploadId(uploadId: string) : void {
    const storageData = this.getStorageData()
    const index = storageData.files.findIndex(fileItem => fileItem.uploadId === uploadId)
    if(index == -1) return
    storageData.files.splice(index, 1)
    // @ts-ignore
    this.localStorage.set(STORAGE_DATA_KEY, storageData)
  }

  updateProgressStatus(status: UpdateProgressStatusInterface, file: File, targetUid: string|null = null) {
    const storageData = this.getStorageData()
    const index = storageData.files.findIndex(fileItem => {
      return this._checkIsSameFileRule(fileItem, file, targetUid)
    })
    if(index === -1) return
    const fileItem = storageData.files[index]
    fileItem.progress = status.progress
    fileItem.uploaded = status.uploaded
    fileItem.total = status.total
    storageData.files[index] = fileItem
    // @ts-ignore
    this.localStorage.set(STORAGE_DATA_KEY, storageData)
  }

  /**
   * 取得未完成的上傳資訊
   * @param targetUid
   */
  getUnfinishedUploadData(targetUid: string|null) : StorageFileItemInterface|null {
    const storageData = this.getStorageData()
    if(!storageData) return null
    const result = storageData.files.find(fileItem => fileItem.targetUid === targetUid)
    return result || null
  }
}

export default storage

export declare interface MultipartUploadStorageInterface {
  getUploadIdByFile(file: File, targetUid: string|null) : string|null
  getUploadDataByFile(file: File, targetUid: string|null) : StorageFileItemInterface|null
  saveUploadIdToStorage(objectKey: string, uploadId: string, file: File, targetUid: string|null) : void
  updateProgressStatus(status: UpdateProgressStatusInterface, file: File, targetUid: string|null) : void
  deleteUploadId(uploadId: string) : void
  getUnfinishedUploadData(targetUid: string) : StorageFileItemInterface|null
}
