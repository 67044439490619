<template>
  <div eagle-multipart-upload-file>
    <div v-if="unfinishedUploadData" class="mb-2">
      <div>{{'multipart_upload.unfinished.file'| t}}: {{unfinishedUploadData.filename}}</div>
      <div>{{'file.size'| t}}: {{getPrettyBytes(unfinishedUploadData.size)}}</div>
      <div>{{'multipart_upload.progress'| t}}: {{unfinishedUploadData.progress}}%</div>
    </div>

    <v-file-input
      dense
      outlined
      ref="fileInput"
      @change="onFileChange"
      :label="$t('file.action.upload')"
      :loading="loading"
      :disabled="loading"
      v-model="file"
    ></v-file-input>

    <v-btn
      :disabled="!file"
      :loading="loading"
      color="primary"
      @click="startMultipartUpload"
    >
      {{'multipart_upload.action.upload'| t}}
    </v-btn>

    <div class="my-4" v-if="hasStatus">
      <v-progress-linear
        :value="status.progress"
        color="primary"
      ></v-progress-linear>

      <div>{{status.progress}}% ({{status.uploaded}}/{{status.total}})</div>
    </div>
  </div>
</template>

<script>
import prettyBytes from 'pretty-bytes'
import multiPartUploadLibrary from 'kernel/libs/multipartUpload/multiPartUploadLibrary.ts'
import stsTokenMixin from 'components/multipartUploadFile/stsTokenMixin'
import multipartUploadFileMixin from 'components/multipartUploadFile/multipartUploadFileMixin'
import uuid from 'uuid'
export default {
  mixins: [multipartUploadFileMixin, stsTokenMixin],
  props: {
    fileValidate: {
      type: Function,
      default: null,
    },
    fileValidateError: {
      type: Function,
      default: null,
    },
    targetUid: {
      type: Function,
      default: null,
    },
    validExtension: {
      type: Array,
      default: null,
    },
    validFileType: {
      type: Array,
      default: null,
    },
    objectAcl: {
      type: String,
      default: 'private',
    },
    bucket: String,
    region: String,
    scope: String,
    setFileObjectKey: Function,
    debug: {
      type: Boolean,
      default: false,
    },
    onUploadCompletedCallback: {
      type: Function,
      default: null,
    },
    onFileChangeCallback: {
      type: Function,
      default: null,
    },
    onFailureCallback: {
      type: Function,
      default: null,
    },
    systemFileType: String,
    systemFileExtraFileBaseUrl: {
      type: String,
      default: null,
    },
    disabledCreateFileData: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    file: null,
    extension: null,
    loading: false,
    fileUid: null,
    multiPartUploadLibrary: null,
    status: {},
    unfinishedUploadData: null,
  }),
  mounted() {
    this.init()
    this.cleanUnusedStsToken()
  },
  methods: {
    getPrettyBytes(size) {
      if(isNaN(size)) return null
      return prettyBytes(size)
    },
    async init() {
      this.multiPartUploadLibrary = new multiPartUploadLibrary({
        debug: this.debug,
      })
      await this.$nextTick()
      this.unfinishedUploadData = this.multiPartUploadLibrary.getUnfinishedUploadData(this.getTargetUid())
    },
    async handleFile() {
      this.extension = this.file.name.split('.').pop()
      this.$store.dispatch('loading/active')
      await this.initStsToken()
      this.$store.dispatch('loading/close')
      if(this.detectAbortUnfinishedUpload() === true) {
        this.abortUnfinishedUploadWarning()
        return
      }
      if(typeof this.onFileChangeCallback === 'function') this.onFileChangeCallback(this.file)
    },
    onFileChange() {
      if(!this.file) return
      if(this.isFileFormatValid() === true) {
        this.handleFile()
        return
      }
      this.file = null
      this.$refs.fileInput.reset()
      let fileValidateError = this.$t('multipart_upload.error.format_incorrect')
      if(typeof this.fileValidateError === 'function') {
        fileValidateError = this.fileValidateError(this.file)
      }

      this.$snotify.error(
        this.$t(`multipart_upload.allow_format`, {
          extension: this.validExtension.join('/')
        }),
        fileValidateError
      )
    },
    isFileFormatValid() {
      if(!this.validateExtension()) return false
      if(!this.validateFileType()) return false
      if(typeof this.fileValidate === 'function') {
        const valid = this.fileValidate(this.file)
        if(!valid) return false
      }
      return true
    },
    validateExtension() {
      if(!this.validExtension) return true
      for(const extension of this.validExtension) {
        if(new RegExp(extension, 'g').test(this.file.name) === true) return true
      }
      return false
    },
    validateFileType() {
      if(!this.validFileType) return true
      for(const fileType of this.validFileType) {
        if(new RegExp(fileType, 'g').test(this.file.type) === true) return true
      }
      return false
    },
    initProvider() {
      const credentials = this.stsToken.Credentials
      this.multiPartUploadLibrary.initMultipartUploadProvider({
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken,
      }, {
        bucket: this.bucket,
        region: this.region,
        acl: this.objectAcl,
      })
    },
    getFileObjectKey() {
      // 預設object key
      let fileObjectKey = this.setFileObjectKey(this.extension, this.fileUid)
      if(!this.unfinishedUploadData) {
        return fileObjectKey
      }
      if(this.file.name != this.unfinishedUploadData.filename) {
        return fileObjectKey
      }

      // 有找到過去上傳一半的紀錄且檔名相同
      return this.unfinishedUploadData.objectKey
    },
    async startMultipartUpload() {
      if(!this.file) return
      if(this.loading) return
      this.initProvider()

      this.loading = true
      this.fileUid = uuid.v4()
      const fileObjectKey = this.getFileObjectKey()
      let isSuccess = false
      try {
        await this.multiPartUploadLibrary.multipartUpload(this.file, {
          targetUid: this.getTargetUid(),
          objectKey: fileObjectKey,
          onProgress: this.onMultipartUploadProgress
        })
        isSuccess = true
      } catch (error) {
        console.error(error)
        if(typeof this.onFailureCallback === 'function') {
          this.onFailureCallback({
            error,
            file: this.file,
          })
        }
        if(this.multiPartUploadLibrary.uploadId) {
          await this.multiPartUploadLibrary.abortMultipart({
            uploadId: this.multiPartUploadLibrary.uploadId,
            key: fileObjectKey,
          })
          this.status = {}
        }

        this.$snotify.error(this.file.name, this.$t('file.upload.failure'))
      }

      this.unfinishedUploadData = this.multiPartUploadLibrary.getUnfinishedUploadData(this.getTargetUid())
      if(isSuccess) {
        await this.afterUploadSuccessfully(fileObjectKey)
      }

      await this.$nextTick()
      this.loading = false
      this.file = null
      this.unfinishedUploadData = null
      this.multiPartUploadLibrary.cleanupTempData()
      window.setTimeout(() => {
        this.status = {}
      }, 1000)
    },
    async afterUploadSuccessfully(fileObjectKey) {
      // 檔案資訊存到file table
      const fileModel = await this.createFileData(fileObjectKey)

      if(typeof this.onUploadCompletedCallback === 'function') {
        try {
          await this.onUploadCompletedCallback({
            fileObjectKey,
            file: this.file,
            fileUid: this.fileUid,
            fileModel,
          })
        } catch (error) {
          console.warn(error)
        }
      }
      this.$snotify.success(this.file.name, this.$t('file.upload.successfully'))
    },
    onMultipartUploadProgress(status) {
      this.status = {
        stage: status.stage,
        uploaded: status.getUploaded(),
        total: status.getTotal(),
        progress: status.getProgress(),
      }
    },
    getTargetUid() {
      if(typeof this.targetUid === 'function') {
        return this.targetUid(this.$route)
      }
      return null
    },
  },
  computed: {
    hasStatus() {
      return !this.$eagleLodash.isEmpty(this.status)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
