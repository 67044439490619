
import storage, {MultipartUploadStorageInterface} from './storage'
import fileHelper, {FileHelperInterface} from './fileHelper'
import multipartUploadProviderS3 from './providers/multipartUploadProviderS3'

// @ts-ignore
import {
  UploadStatusInterface,
  MultipartUploadConfigInterface,
  InitProviderConfigInterface,
  ListPartsRequestParams,
  MultipartUploadPart,
  CompleteMultipartUploadRequestParams,
  CompleteMultipartUploadResult,
  UploadFilePartRequestParams,
  UnfinishedUploadFiles,
  AbortMultipartRequestParams,
  StorageFileItemInterface,
// @ts-ignore
} from 'kernel/libs/multipartUpload/multipartUploadType'

class MultiPartUploadLibrary {
  debugMode: boolean
  // @ts-ignore
  file: File

  // @ts-ignore
  fileHelper: FileHelperInterface

  // @ts-ignore
  targetUid: string|null // 上傳檔案綁定的實體ID, 不綁定亦可

  // @ts-ignore
  objectKey: string // object key(上傳位置)
  status: UploadStatusInterface

  // @ts-ignore
  config: MultipartUploadConfigInterface

  // @ts-ignore
  storage: MultipartUploadStorageInterface

  // @ts-ignore
  uploadId: string

  // @ts-ignore
  onProgress: (status: UploadStatusInterface) => void

  // @ts-ignore
  uploadedChunks: MultipartUploadPart[]

  // @ts-ignore
  provider: multipartUploadProviderS3

  // @ts-ignore
  uploadResult: CompleteMultipartUploadResult

  bucket: string
  acl: string

  constructor(config: { debug?: boolean }) {
    this.acl = ''
    this.bucket = ''
    this.uploadId = ''
    this.debugMode = config.debug === true
    this.storage = new storage()
    this.status = <UploadStatusInterface>{
      getUploaded: () => this.getUploadedChunkQuantity(),
      getTotal: () => this.fileHelper.getChunkQuantity(),
      getProgress: () => this.getProgress(),
      stage: 'init',
    }
  }

  /**
   * 取得已上傳片段(chunk)數量
   * @return {number}
   */
  getUploadedChunkQuantity() : number {
    if(!Array.isArray(this.uploadedChunks)) return 0
    return this.uploadedChunks.length
  }

  /**
   * 取得上傳進度
   * @return {number}
   */
  getProgress() : number {
    const uploaded = this.getUploadedChunkQuantity()
    const total = this.fileHelper.getChunkQuantity()
    if(!total) return 0
    return Math.floor((uploaded/total)*100)
  }

  _logger(message: any, ...args: any) : void {
    if(!this.debugMode) return
    console.warn(`[DEBUG]`, message, ...args)
  }

  /**
   * 初始化provider
   * @param credentials 憑證物件
   * @param config 設定
   */
  initMultipartUploadProvider(credentials: {[key: string]: any}, config: InitProviderConfigInterface) : void {
    this.bucket = config.bucket
    this.acl = config.acl
    this.provider = multipartUploadProviderS3
    this.provider.init(credentials, config)
  }

  async multipartUpload(file: File, config: MultipartUploadConfigInterface) : Promise<void> {
    this.file = file
    this.fileHelper = new fileHelper(file)
    this.onProgress = config.onProgress
    this.targetUid = config.targetUid || null
    this.objectKey = config.objectKey

    // 設定Multipart UploadId
    this.status.stage = 'setupMultiPartUploadId'
    this.callOnProgressCallback()
    await this._setupMultiPartUploadId()

    // 確認已上傳片段
    this.status.stage = 'checkUploadedChunks'
    this.callOnProgressCallback()
    await this._checkUploadedChunks()

    // 開始上傳切片
    this.status.stage = 'uploadChunks'
    this.callOnProgressCallback()
    await this._uploadChunks()

    // 合併檔案切片
    this.status.stage = 'completed'
    this.callOnProgressCallback()
    await this._mergeFileChunks()
  }

  // 清除storage的暫存檔案資訊
  cleanupTempData() : void {
    this.status.stage = 'cleanup'
    this.callOnProgressCallback()
    if(this.uploadId) this.storage.deleteUploadId(this.uploadId)
    this.uploadedChunks = []
  }

  /**
   * 取得UploadId並assign至this.uploadId
   * 不存在就發createMultipartUpload API取得UploadId
   * @returns {Promise<void>}
   * @private
   */
  async _setupMultiPartUploadId() {
    this._logger('call _setupMultiPartUploadId()')

    /**
     * 找storage是否有存起來的檔案
     * 有就直接設定取代當下的this.uploadId、this.objectKey拿來用
     * 在檢查已上傳的片段(listParts API)需要用到
     */
    const storageUploadData = this.storage.getUploadDataByFile(this.file, this.targetUid)
    if(storageUploadData) {
      this.uploadId = storageUploadData.uploadId
      this.objectKey = storageUploadData.objectKey
      return
    }

    const result = await this.provider.createMultipartUpload({
      bucket: this.bucket,
      objectKey: this.objectKey,
      fileType: this.file.type,
      acl: this.acl,
    })

    this.uploadId = result.uploadId
    this.storage.saveUploadIdToStorage(this.objectKey, this.uploadId, this.file, this.targetUid)
    this._logger(result)
    return result
  }

  /**
   * 調用外部帶入的onProgress callback function(config.onProgress)
   * @returns {void}
   */
  callOnProgressCallback() : void{
    if(typeof this.onProgress != 'function') return
    this.onProgress(this.status)
  }

  /**
   * 取消續傳
   * @returns {Promise<void>}
   */
  async abortMultipart(params: AbortMultipartRequestParams) : Promise<void> {
    if(!params.uploadId) return
    this._logger('call abortMultipart()')
    try {
      const result = await this.provider.abortMultipart({
        uploadId: params.uploadId,
        objectKey: params.key,
        bucket: this.bucket,
      })
    } catch (error) {
      console.warn(error)
    } finally {
      this.storage.deleteUploadId(this.uploadId)
    }
  }

  /**
   * 檢查已上傳的片段(listParts API)
   * @returns {Promise<void>}
   * @private
   */
  async _checkUploadedChunks(nextPartNumberMarker: number|null = null) {
    this._logger('call _checkUploadedChunks()')
    const params = <ListPartsRequestParams>{
      objectKey: this.objectKey,
      uploadId: this.uploadId,
      bucket: this.bucket,
    }
    if(nextPartNumberMarker) {
      params.nextPartNumberMarker = nextPartNumberMarker
    }
    const result = await this.provider.listParts(params)
    if(!this.uploadedChunks) {
      this.uploadedChunks = []
    }

    const uploadedParts = result.parts.map((part: any) => ({
      partNumber: part.partNumber,
      eTag: part.eTag,
    }))
    this.uploadedChunks = this.uploadedChunks.concat(uploadedParts)
    this.uploadedChunks = this._getSortUploadChunks()
    this._logger(result)

    // 代表還有其他片段
    if(result.isTruncated === true && result.nextPartNumberMarker > 0) {
      await this._checkUploadedChunks(result.nextPartNumberMarker)
    }
  }

  async _mergeFileChunks() {
    const params = <CompleteMultipartUploadRequestParams>{
      objectKey: this.objectKey,
      parts: this._getSortUploadChunks(),
      uploadId: this.uploadId,
      bucket: this.bucket,
    }
    this._logger(`開始合併檔案(completeMultipartUpload)`, params)
    const result = await this.provider.completeMultipartUpload(params)
    this._logger(`檔案合併完成`, result)
    this.uploadResult = result
  }

  _getSortUploadChunks() {
    // @ts-ignore
    return window.eagleLodash.sortBy(this.uploadedChunks, [item => item.partNumber])
  }

  /**
   * 開始處理上傳所有片段
   * @returns {Promise<void>}
   */
  async _uploadChunks() {
    // @ts-ignore
    await this.fileHelper.scanChunks(async (...args) => {
      // @ts-ignore
      await this.handleEachFileChunk(...args)
    })
    this._logger(`處理完所有片段`)
  }

  /**
   * 處理各個檔案片段
   * @param chunkNo 片段編號
   * @param fileChunk 檔案片段
   * @returns {Promise<void>}
   */
  async handleEachFileChunk(chunkNo: number, fileChunk: Blob) {
    const isChunkUploaded = this._checkIsUploadedChunk(chunkNo)
    this._logger(`正在處理檔案片段: ${chunkNo}`)

    // 在已上傳片段找到, 不用再上傳直接跳過
    if(isChunkUploaded === true) {
      this._logger(`片段已上傳過: ${chunkNo}`)
      this.callOnProgressCallback()
      return
    }

    const params = <UploadFilePartRequestParams> {
      fileChunk: fileChunk,
      path: this.objectKey,
      partNumber: chunkNo,
      uploadId: this.uploadId,
      bucket: this.bucket,
    }
    this._logger(`開始上傳片段到storage(uploadPart): ${chunkNo}`, params)
    const result = await this.provider.uploadPart(params)

    // 加入已上傳區段
    this.uploadedChunks.push({
      partNumber: chunkNo,
      eTag: result.etag,
    })
    this.callOnProgressCallback()
    this.storage.updateProgressStatus(
      {
        uploaded: this.getUploadedChunkQuantity(),
        total: this.fileHelper.getChunkQuantity(),
        progress: this.getProgress(),
      },
      this.file,
      this.targetUid
    )
    this._logger(`片段上傳成功: ${chunkNo}`, result)
  }

  /**
   * 檢查指定片段(傳入片段編號chunkNo)是否已經上傳
   * @param chunkNo
   * @returns {boolean}
   */
  _checkIsUploadedChunk(chunkNo: number) : boolean {
    if(!this.uploadedChunks) return false
    if(!Array.isArray(this.uploadedChunks)) return false
    if(!this.uploadedChunks.length) return false
    return this.uploadedChunks.findIndex(part => part.partNumber == chunkNo) > -1
  }

  /**
   * 列出所有未上傳完成的檔案資訊
   */
  async listMultipartUploads(bucket: string) : Promise<UnfinishedUploadFiles> {
    return await this.provider.listMultipartUploads(bucket)
  }

  /**
   * 刪除storage暫存的檔案資訊
   * @param uploadId {string}
   */
  async deleteStorageUploadId(uploadId: string) : Promise<void> {
    this.storage.deleteUploadId(uploadId)
  }

  getUnfinishedUploadData(targetUid: string) : StorageFileItemInterface|null {
    return this.storage.getUnfinishedUploadData(targetUid)
  }

  /**
   * 列出所有未上傳完成的檔案資訊
   */
  async getObjectAttributes(objectKey: string) : Promise<any> {
    return await this.provider.getObjectAttributes({
      bucket: this.bucket,
      objectKey: objectKey,
    })
  }
}

export default MultiPartUploadLibrary

export declare interface MultiPartUploadLibraryInterface {
  // 初始化provider, credentials物件依照各provider自行決定
  initMultipartUploadProvider(credentials: {[key: string]: any}, config: InitProviderConfigInterface) : void

  // 列出所有未上傳完成的檔案資訊
  listMultipartUploads() : Promise<UnfinishedUploadFiles[]>

  // 取消續傳
  abortMultipart(params: AbortMultipartRequestParams) : Promise<void>

  // 開始進行續傳流程
  multipartUpload(file: File, config: MultipartUploadConfigInterface) : Promise<void>

  // 刪除storage暫存的檔案資訊
  deleteStorageUploadId(uploadId: string) : Promise<void>

  // 取得未完成的檔案上傳資訊
  getUnfinishedUploadData(targetUid: string) : StorageFileItemInterface

  // 取得物件屬性
  getObjectAttributes(objectKey: string) : Promise<any>

  // 清除storage的暫存檔案資訊
  cleanupTempData() : void
}
